import React, { useState } from 'react';
import NavigationBar from '../Navbar/NavigationBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './QuizCategory.css';
import Footer from '../Footer/Footer';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { width } from '@fortawesome/free-solid-svg-icons/fa0';
const QuizCategory = () => {

  const engineeringItems = [
    { label: 'Civil Engineering', link: '/#' },
    { label: 'Electrical Engineering', link: '/#' },
    { label: 'Mechanical Engineering', link: '/#' },
    { label: 'Electronics and Comm. Engineering', link: '/#' }
  ];

  const programmingLanguageItems = [
    { label: 'C', link: '/cprogramming' },
    { label: 'Java', link: '/java' },
   
  ];

  const Tnspc = [
    
    { label: 'Group IV', link: '/Tnpsc/IV' },   
  ];

  const renderItemsAsColumns = (items) => {
    return items.map((item, index) => (
      <div className="column" key={index}>
        <div className="card">
          <h3 className="card-title">{item.title}</h3>
          <div className="card-content">
            <div>
              {item.data.map((dataItem, dataIndex) => (
                <ul key={dataIndex}>
                  <li>
                    <FontAwesomeIcon icon={faAngleRight} className="icon" />
                    <a className="card-items" href={dataItem.link}>{dataItem.label}</a>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const columnsData = [
    { title: 'Programming Languages', data: programmingLanguageItems },
   // { title: 'Engineering', data: engineeringItems },
    {title:'TNPSC',data:Tnspc},
  ];
  const slideImages = [
    {
      url: 'mcq/home1.png',
    },
    {
      url: 'mcq/home2.png',
    }
  ];
 
  const divStyle = {
    margin:'auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '300px',
  };

  return (
    
    <div>
      <NavigationBar />
      <div className="slide-container" >
      <Slide>
        {slideImages.map((slideImage, index)=> (
          <div key={index}>
            <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})`, }}>
            </div>
          </div>
        ))} 
      </Slide>
    </div>
      <div className="columns">
        {columnsData.map((column, columnIndex) => (
          <React.Fragment key={columnIndex}>
            {renderItemsAsColumns([column])}
          </React.Fragment>
        ))}
      </div>
      <div className='home-description'>
        <p>
        MCQ KIT is your ultimate destination for comprehensive multiple-choice questions (MCQs) covering a wide range of topics. Whether you're a student preparing for exams, a professional looking to brush up on your skills, or simply someone eager to expand your knowledge, MCQ KIT has you covered. With an extensive collection of MCQs spanning subjects such as mathematics, science, history, literature, and more, you'll find exactly what you need to test your understanding and reinforce key concepts. Our user-friendly interface makes it easy to navigate through different topics and select quizzes tailored to your interests and proficiency level. Plus, with regular updates and new questions added frequently, there's always something fresh to challenge yourself with. Start your learning journey today with MCQ KIT and take your knowledge to new heights!
        </p>
      </div>
      <Footer/>
    </div>  
  );
};

export default QuizCategory;
